<template>
  <router-link
    class="tpos"
    :style="
      hasCover
        ? `backgroundImage: url(${test.cover})`
        : 'backgroundColor: #edf1f8'
    "
    :to="link"
  >
    <ExamPermissionWrapper :isBlockToeflTest="isBlockToeflTest">
      <div class="l-block">
        <div v-if="test" :class="`ibottom ${hasCover ? 'coverMask' : ''}`">
          <label class="ileft">{{ title }}</label>
        </div>
        <div v-else class="ibottom">
          <b class="ileft" style="font-size:18px">{{ title }}</b>
        </div>
      </div>
      <div v-if="isBlockToeflTest" class="r-block">
        <a class="start_test start">
          <i class="fas fa-lock" />
          {{ $t("toefl.Pro Plans") }}
        </a>
      </div>
      <div v-else class="r-block">
        <a class="start_test start">Start</a>
      </div>
    </ExamPermissionWrapper>
  </router-link>
</template>

<script>
import ExamPermissionWrapper from "@/views/toefl/toeflList/components/ExamPermissionWrapper.vue";

export default {
  props: ["test", "title", "card_title", "testType", "version", "isBrowse", "tagId", "isFreeMember"],
  components: {
    ExamPermissionWrapper
  },
  data() {
    return {
      hasCover: false,
      link: {}
    };
  },
  computed: {
    name() {
      let name = "SetTest";
      if (document.body.clientWidth <= 768) {
        name = "MSetTest";
      }
      return name;
    },
    isBlockToeflTest() {
      const PRO_TAG_ID = 12;
      return this.isFreeMember && this.tagId === PRO_TAG_ID;
    }
  },
  watch: {},
  mounted() {
    this.checkIfHasCover();
    this.startTest();
  },
  methods: {
    async checkIfHasCover() {
      const imageExists = async imgUrl => {
        if (!imgUrl) {
          return false;
        }
        return new Promise(res => {
          const image = new Image();
          image.onload = () => res(true);
          image.onerror = () => res(false);
          image.src = imgUrl;
        });
      };

      if (this.test && this.test.cover) {
        const result = await imageExists(this.test.cover);
        this.hasCover = result;
      } else {
        this.hasCover = false;
      }
    },
    startTest() {
      let link = null;
      if (this.isBrowse) {
        link = {
          name: "ToeflResolveBrowse",
          query: { id: this.test.id }
        };
      } else {
        if (this.test === undefined) {
          link = {
            name: this.name,
            query: {
              title: this.card_title,
              testType: this.testType,
              version: this.version
            }
          };
        } else {
          let id = this.test.id;
          link = {
            name: this.name,
            query: {
              chapter_id: id,
              type: this.testType,
              version: this.version
            }
          };
        }
      }
      this.link = link;
    }
  }
};
</script>

<style scoped>
.tpos {
  display: block;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  height: 100px;
  box-sizing: border-box;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;
  color: var(--themeColor);
}
.tpos:hover .l-block {
  display: none;
}

.tpos:hover .r-block {
  display: block;
}
.tpos:hover .start {
  color: #fff !important;
}

.ibottom {
  text-align: center;
}

.coverMask {
  text-align: center;
  background: #666666aa;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: #fff;
  font-weight: bold;
}

.l-block {
  width: 100%;
  height: 100px;
  display: block;
  float: left;
  position: relative;
}
.l-block .ibottom{
  height: 100%;
}
.l-block .ileft {
  margin-left: 0;
  text-align: center;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  height: 100%;
  text-align: center;
  padding: 10px;
}

.l-block p {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
}

.itop h3,
.l-block p,
.ibottom label {
  display: inline-block;
}
.r-block {
  bottom: 28px;
  top: 36px;
  width: 100%;
  display: none;
  position: relative;
  height: 100px;
  float: right;
  text-align: center;
}

.r-block a:first-child {
  display: block;
  top: 0px;
  background-color: var(--themeColor);
}
.r-block a {
  line-height: 100px;
  width: max-content;
  padding: 2px 24px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  border-radius: 50em;
  color: #fff;
  text-align: center;
  line-height: 24px;
  transition: 0.5s 0s;
  border: 2px solid white;
}
a {
  user-select: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
</style>
