<template>
  <div class="stretch" @click="checkExamPermission">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["isBlockToeflTest"],
  methods: {
    checkExamPermission(e) {
      if (this.isBlockToeflTest) {
        this.$confirm(
          this.$t("toefl.planTipsUseLockExam"),
          this.$t("message.notice"),
          {
            showClose: false,
            confirmButtonText: this.$t("message.pro_plan"),
            cancelButtonText: this.$t("flashCard.i18n30")
          }
        )
          .then(() => {
            this.$router.push({
              name: "ToeflPlans"
            });
          })
          .catch(() => {});
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.stretch {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
</style>
