<template>
  <div>
    <!-- <h2 class="text-center">
      {{ $t("toefl.typeList." + testType) }}
    </h2> -->
    <hr />
    <div class="row">
      <div class="toefl-sections-xs">
        <!-- <div class="toefl-sections-header-xs">
          {{ $t("toefl.single_test") }}
        </div> -->
        <div class="toefl-sections-content-xs">
          <div class="btn-item">
            <a @click="selectType('reading')">
              <label :class="testType == 'reading' ? 'btn active' : 'btn'">
                <i class="fas fa-book-open" />
              </label>
            </a>
          </div>
          <div class="btn-item">
            <a @click="selectType('listening')">
              <label :class="testType == 'listening' ? 'btn active' : 'btn'">
                <i class="fas fa-headphones-alt" /> </label
            ></a>
          </div>
          <div class="btn-item" v-show="!ios">
            <a @click="selectType('speaking')">
              <label :class="testType == 'speaking' ? 'btn active' : 'btn'">
                <i class="fas fa-microphone-alt" /> </label
            ></a>
          </div>
          <div class="btn-item">
            <a @click="selectType('writing')">
              <label :class="testType == 'writing' ? 'btn active' : 'btn'">
                <i class="fas fa-pencil-alt" /> </label
            ></a>
          </div>
        </div>
      </div>
      <div class="col-lg-2 text-right ivy-type-title">
        {{ $t("toefl.mock_test") }}
      </div>
      <div class="col-12 col-md-10 btn-list">
        <div class="btn-item-box">
          <div class="btn-item-type toefl-sections">
            <!-- <a @click="selectType('reading')">
              <label
                :class="
                  testType != 'mock' && testType != 'quick' ? 'btn active' : 'btn'
                "
              >
                {{ $t("toefl.single_test") }}
              </label>
            </a> -->
            <div class="toefl-sections-content-xs">
              <div class="btn-item">
                <a @click="selectType('reading')">
                  <label :class="testType == 'reading' ? 'btn active' : 'btn'">
                    <i class="fas fa-book-open" />
                  </label>
                </a>
              </div>
              <div class="btn-item">
                <a @click="selectType('listening')">
                  <label
                    :class="testType == 'listening' ? 'btn active' : 'btn'"
                  >
                    <i class="fas fa-headphones-alt" /> </label
                ></a>
              </div>
              <div class="btn-item" v-show="!ios">
                <a @click="selectType('speaking')">
                  <label :class="testType == 'speaking' ? 'btn active' : 'btn'">
                    <i class="fas fa-microphone-alt" /> </label
                ></a>
              </div>
              <div class="btn-item">
                <a @click="selectType('writing')">
                  <label :class="testType == 'writing' ? 'btn active' : 'btn'">
                    <i class="fas fa-pencil-alt" /> </label
                ></a>
              </div>
            </div>
          </div>
        </div>
        <template v-if="!isBrowse">
          <div class="btn-item-box">
            <div class="btn-item-type">
              <a @click="selectType('mock')">
                <label :class="testType == 'mock' ? 'btn active' : 'btn'">
                  {{ $t("toefl.typeList.mock") }}
                </label>
              </a>
            </div>
          </div>
          <div class="btn-item-box">
            <div class="btn-item-type">
              <a @click="selectType('quick')">
                <label :class="testType == 'quick' ? 'btn active' : 'btn'">
                  {{ $t("toefl.typeList.quick") }}
                </label>
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="d-xs-none d-sm-none d-md-none">
      <div class="row" v-if="testType != 'mock' && testType != 'quick'">
        <div class="col-md-2 text-right ivy-type-title toefl-sections">
          {{ $t("toefl.single_test") }}
        </div>
        <div class="col-sm-12 col-md-10 btn-list">
          <div class="btn-item">
            <a @click="selectType('reading')">
              <label :class="testType == 'reading' ? 'btn active' : 'btn'">
                {{ $t("toefl.typeList.reading") }}
              </label></a
            >
          </div>
          <div class="btn-item">
            <a @click="selectType('listening')">
              <label :class="testType == 'listening' ? 'btn active' : 'btn'">
                {{ $t("toefl.typeList.listening") }}
              </label></a
            >
          </div>
          <div class="btn-item" v-show="!ios">
            <a @click="selectType('speaking')">
              <label :class="testType == 'speaking' ? 'btn active' : 'btn'">
                {{ $t("toefl.typeList.speaking") }}
              </label></a
            >
          </div>
          <div class="btn-item">
            <a @click="selectType('writing')">
              <label :class="testType == 'writing' ? 'btn active' : 'btn'">
                {{ $t("toefl.typeList.writing") }}
              </label></a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <el-alert
      v-if="ios"
      type="warning"
      show-icon
      center
      :closable="false"
      style="margin: 10px 0"
    >
      <span v-html="$t('toefl.iOSAlert')"></span>
    </el-alert> -->
    <hr />
  </div>
</template>

<script>
import ToeflMixin from "@/mixins/toefl.js";
export default {
  components: {},

  mixins: [ToeflMixin],

  props: ["testType", "isNew", "isBrowse"],
  data() {
    return {
      typeList: this.$t("toefl.typeList"),
      ios: this.isIOS(),
      sat: null
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    selectType(testType) {
      let name = "LandingPage";
      if (this.isBrowse) {
        name = "Browse";
      }
      this.$router.replace({
        name: name,
        query: {
          testType: testType,
          isNew: this.isNew
        }
      });
    }
  }
};
</script>

<style scoped>
.ivy-type-title {
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  margin: 10px 0;
}
.btn-list {
  display: flex;
  justify-content: space-between;
}
.btn-item-box {
  width: 33%;
  padding: 0 10px;
}
.btn-item {
  width: 200px;
}
.btn-item-type {
  width: 100%;
}
.btn {
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
}
.btn:hover,
.active {
  color: white;
  background: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}
.ivy-type-title {
  display: block;
}
.toefl-sections-xs {
  display: none;
}
.toefl-sections-content-xs .btn-item {
  padding: 0 5px;
}
.toefl-sections-content-xs {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .btn-item-type {
    width: 240px;
  }
  .btn-item {
    width: 180px;
  }
}
/* @media screen and (max-width: 992px) {
  .btn-item-type {
    width: 240px;
  }
  .btn-item {
    width: 180px;
  }
} */
@media screen and (max-width: 992px) {
  .ivy-type-title {
    text-align: center !important;
    height: 25px;
    line-height: 25px;
    margin: 0;
  }
  .d-xs-none {
    display: none;
  }
  .btn-list {
    display: block;
  }
  .btn-item-type,
  .btn-item-box,
  .btn-item {
    width: 100%;
  }
  .btn-item-box {
    padding: 0;
  }
  .ivy-type-title {
    display: none;
  }

  .toefl-sections {
    display: none;
  }

  .toefl-sections-xs {
    display: block;
    width: 100%;
  }

  .toefl-sections-xs > .toefl-sections-header-xs {
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: var(--themeColor);
    color: #fff;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    padding: 0 5px;
    margin-top: 15px;
  }

  .toefl-sections-xs > .toefl-sections-content-xs {
    height: 60px;
    /* border: 1px solid #dcdcdc; */
    border-radius: 0px 0px 5px 5px;
    display: flex;
    padding: 0px 9px;
  }

  .toefl-sections-xs > .toefl-sections-content-xs > .btn-item {
    padding: 0px 6px;
  }
}
</style>
