var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"tpos",attrs:{"to":{
    name: this.name,
    query: {
      title: this.test.title,
      testType: this.testType,
      version: this.version
    }
  }}},[_c('ExamPermissionWrapper',{attrs:{"isBlockToeflTest":_vm.isBlockToeflTest}},[_c('div',{staticClass:"l-block"},[_c('b',{staticClass:"ileft",staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.test.title))])]),(_vm.isBlockToeflTest)?_c('div',{staticClass:"r-block"},[_c('a',{staticClass:"start_test start"},[_c('i',{staticClass:"fas fa-lock"}),_vm._v(" "+_vm._s(_vm.$t("toefl.Pro Plans"))+" ")])]):_c('div',{staticClass:"r-block"},[_c('a',{staticClass:"start_test start"},[_vm._v("Start Test")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }